import { AppDispatch, AppGetState, GlobalState } from '@/redux/store';
import {
    CARD_REAUTH_FAILURE,
    CARD_REAUTH_REQUEST,
    CARD_REAUTH_SUCCESS,
    DISMISS_MODAL,
    OPEN_REGISTER_FOR_CATALOG_MODAL,
    REGISTER_FOR_CATALOG_FAIL,
    REGISTER_FOR_CATALOG_REQUEST,
    REGISTER_FOR_CATALOG_SUCCESS,
} from './actions';
import { createSelector } from '@reduxjs/toolkit';
import { fetchBidLimitBalanceIfNeeded } from './bidLimit';
import { getAmplitudePageNameFromPath } from '@/utils/getPageNameFromPath';
import { getAuthToken, getBidderId } from '@/redux/modules/account/user/user.selectors';
import {
    getCatalogIsAutopay,
    getCatalogIsInstantAutopay,
    getCatalogIsLAPayments,
    getCatalogPaymentProvider,
    getCatalogSellerId,
} from '@/redux/modules/catalog/catalogs/catalog.selectors';
import { getDeployment } from './config';
import { getFullUrl } from '@/utils/analytics/utils/getFullUrl';
import { getHasValidCardCatalog, postRegisterForCatalog } from '@/redux/modules/catalog/catalogs/catalog.api';
import { getRequestedAuctionRegistrationAnalytics, trackConversionForAlgolia } from './analytics';
import { laPaymentCardsSelector } from './creditCard';
import { trackAuctionRegistrationSubmittedAnalytics, TrackAuctionRegistrationSubmittedType } from '@/utils/analytics';
/* reducer */

type RegisterForCatalogSlice = {
    cardAuthFailedIds: number[];
    cardAuthLoading: boolean;
    cardAuthSuccessIds: number[];
    catalogId: number;
    error: any;
    itemId: number;
    submitted: boolean;
    success: boolean;
};

export const defaultRegisterForCatalogSlice: RegisterForCatalogSlice = {
    cardAuthFailedIds: [],
    cardAuthLoading: false,
    cardAuthSuccessIds: [],
    catalogId: 0,
    error: undefined,
    itemId: 0,
    submitted: false,
    success: false,
};

export default function reducer(
    state: RegisterForCatalogSlice = defaultRegisterForCatalogSlice,
    action: any = {}
): RegisterForCatalogSlice {
    switch (action.type) {
        case OPEN_REGISTER_FOR_CATALOG_MODAL:
            return {
                ...state,
                catalogId: action.payload.catalogId,
                itemId: action.payload.itemId ? action.payload.itemId : 0,
            };
        case REGISTER_FOR_CATALOG_FAIL:
            return {
                ...state,
                error: action.payload,
                submitted: false,
                success: false,
            };
        case REGISTER_FOR_CATALOG_REQUEST:
            return {
                ...state,
                submitted: true,
                success: false,
            };
        case REGISTER_FOR_CATALOG_SUCCESS:
            return {
                ...state,
                submitted: false,
                success: true,
            };
        case CARD_REAUTH_REQUEST:
            return {
                ...state,
                cardAuthLoading: true,
            };
        case CARD_REAUTH_FAILURE:
            return {
                ...state,
                cardAuthFailedIds: [...state.cardAuthFailedIds, action.payload],
                cardAuthLoading: false,
            };
        case CARD_REAUTH_SUCCESS:
            return {
                ...state,
                cardAuthLoading: false,
                cardAuthSuccessIds: [...state.cardAuthSuccessIds, action.payload],
            };

        case DISMISS_MODAL:
            return defaultRegisterForCatalogSlice;
        default:
            return state;
    }
}

/* SELECTORS */
const stateSelector = (state: GlobalState) => state;
const registerForCatalogSelector = createSelector(stateSelector, (state) => state.registerForCatalog);

export const getUserRegisterForCatalog = createSelector(
    registerForCatalogSelector,
    (catalogRegistrations) => catalogRegistrations
);

export const getRegisterForAuctionSubmitted = createSelector(registerForCatalogSelector, (slice) => slice.submitted);
export const getRegisterForAuctionSuccess = createSelector(registerForCatalogSelector, (slice) => slice.success);
export const getRegisterForAuctionCatalogId = createSelector(registerForCatalogSelector, (slice) => slice.catalogId);
export const getRegisterForAuctionItemId = createSelector(registerForCatalogSelector, (slice) => slice.itemId);
export const getAuthFailedCardIds = createSelector(registerForCatalogSelector, (slice) => slice.cardAuthFailedIds);
export const getAuthSuccessfulCardIds = createSelector(registerForCatalogSelector, (slice) => slice.cardAuthSuccessIds);
export const getCardAuthLoading = createSelector(registerForCatalogSelector, (slice) => slice.cardAuthLoading);

/* ACTION CREATORS */

export const checkHasValidCardForCatalog =
    (catalogId: number, cardId: number) => async (dispatch: Function, getState: Function) => {
        const state = getState();
        const authToken = getAuthToken(state);
        const bidderId = getBidderId(state);
        const deployment = getDeployment(state);
        const sellerId = getCatalogSellerId(state, catalogId);
        const isInstantAutopay = getCatalogIsInstantAutopay(state, catalogId);
        const isAutopay = getCatalogIsAutopay(state, catalogId);
        const paymentProvider = getCatalogPaymentProvider(state, catalogId);

        if (isAutopay || isInstantAutopay) {
            // if they have no cards at all, return false
            const bidderCards = laPaymentCardsSelector(state);
            if (!Boolean(bidderCards.length)) {
                return false;
            }

            await dispatch({
                payload: cardId,
                type: CARD_REAUTH_REQUEST,
            });

            // check if they have a valid card to pay if they win
            const validCardResponse = await getHasValidCardCatalog({
                authToken,
                bidderId,
                cardId,
                deployment,
                houseId: sellerId,
                providerId: paymentProvider,
            });

            if (!validCardResponse?.payload) {
                // add card id to list of auth failed ids
                await dispatch({
                    payload: cardId,
                    type: CARD_REAUTH_FAILURE,
                });
            } else {
                // add card id to list of auth success ids
                await dispatch({
                    payload: cardId,
                    type: CARD_REAUTH_SUCCESS,
                });
            }
            return validCardResponse?.payload || false;
        }
        return true;
    };

export type SubmitRegisterForCatalogParams = {
    cardId?: number;
    catalogId: number;
    itemId: number;
    minimumBidLimit?: boolean;
    registrationType?: TrackAuctionRegistrationSubmittedType;
};

export const submitRegisterForCatalog =
    ({
        cardId = 0,
        catalogId,
        itemId,
        minimumBidLimit = false,
        registrationType = TrackAuctionRegistrationSubmittedType.User,
    }: SubmitRegisterForCatalogParams) =>
    async (dispatch: AppDispatch, getState: AppGetState) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const bidderId = getBidderId(state);
            const deployment = getDeployment(state);
            const isLivePayments = getCatalogIsLAPayments(state, catalogId);

            const hasCardToRegister = await dispatch(checkHasValidCardForCatalog(catalogId, cardId));

            if (isLivePayments && !hasCardToRegister && !minimumBidLimit) {
                return;
            }

            dispatch({ type: REGISTER_FOR_CATALOG_REQUEST });

            const response = await postRegisterForCatalog({
                authToken,
                bidderId,
                catalogId,
                deployment,
                itemId,
                minimumBidLimit,
            });

            const analytics = getRequestedAuctionRegistrationAnalytics(state, catalogId, itemId);
            dispatch({
                meta: { analytics: analytics.analytics },
                payload: response.payload,
                type: REGISTER_FOR_CATALOG_SUCCESS,
            });

            trackAuctionRegistrationSubmittedAnalytics({
                pageName: getAmplitudePageNameFromPath(window.location.pathname),
                registrationType,
                url: getFullUrl(deployment, window.location.pathname),
            });

            await Promise.allSettled([
                dispatch(trackConversionForAlgolia(itemId)),
                dispatch(fetchBidLimitBalanceIfNeeded({ catalogId })),
            ]);
        } catch (error) {
            dispatch({
                error: true,
                meta: { catalogId },
                payload: error,
                type: REGISTER_FOR_CATALOG_FAIL,
            });
        }
    };
