import { createSelector } from '@reduxjs/toolkit';
import { GlobalState } from '@/redux/store';

export const getPriceResults = ({ priceResults }: GlobalState) => priceResults;

export const getMedianPrice = createSelector(getPriceResults, ({ medianPrice }) => medianPrice);

export const getPriceRangeHigh = createSelector(getPriceResults, ({ priceRangeHigh }) => priceRangeHigh);

export const getPriceRangeLow = createSelector(getPriceResults, ({ priceRangeLow }) => priceRangeLow);

export const getSalesHistoryItemIds = createSelector(getPriceResults, ({ salesHistoryItemIds }) => salesHistoryItemIds);

export const getFirstSalesHistoryItemId = createSelector(
    getPriceResults,
    ({ salesHistoryItemIds }) => salesHistoryItemIds?.[0] || 0
);

export const getSoldSort = createSelector(getPriceResults, ({ soldSort }) => soldSort);

export const getUpcomingSort = createSelector(getPriceResults, ({ upcomingSort }) => upcomingSort);

export const getTotalSales = createSelector(getPriceResults, ({ totalSales }) => totalSales);

export const getUpcomingSalesItemIds = createSelector(
    getPriceResults,
    ({ upcomingSalesItemIds }) => upcomingSalesItemIds
);

export const getViewMoreUrl = createSelector(getPriceResults, ({ viewMoreUrl }) => viewMoreUrl);

export const getSavedSearchKey = createSelector(getPriceResults, ({ savedSearchKey }) => savedSearchKey);

export const getShowPage = createSelector(getPriceResults, ({ showPage }) => showPage);

export const getSlug = createSelector(getPriceResults, ({ slug }) => slug);

export const getSlugItemId = createSelector(
    [getPriceResults, getSalesHistoryItemIds],
    ({ slugItemId }, [firstItemId]) => slugItemId || firstItemId
);
