import * as api from './priceResults.api';
import { createAsyncThunk } from '@/redux/createAsyncThunk';
import { getAuthToken } from '@/redux/modules/account/user/user.selectors';
import { getClientIpAddress } from '../browser';
import { getDeployment } from '../config';
import { getIsBot } from '../analytics';
import {
    GetPriceResultsDataPayload,
    GetPriceResultsParams,
    GetPriceResultsPayload,
    PageType,
} from './priceResults.types';
import { getShowPage, getSoldSort, getUpcomingSort } from './priceResults.selectors';
import { OmitAuthAndDeployment } from '@/redux/commonTypes';

type ActionResult = GetPriceResultsPayload & { pageType?: PageType };
type ActionArgs = OmitAuthAndDeployment<GetPriceResultsParams> & {
    pageType?: PageType;
};

const fetchGetPriceResults = async ({ pageType, ...rest }: ActionArgs, { getState, rejectWithValue }) => {
    const state = getState();
    const authToken = getAuthToken(state);
    const deployment = getDeployment(state);
    const ipAddress = getClientIpAddress(state);
    const isBot = getIsBot(state);
    const showPage = getShowPage(state);
    const soldSort = getSoldSort(state);
    const upcomingSort = getUpcomingSort(state);

    if (!showPage) {
        return rejectWithValue('Useless call');
    }

    const { payload } = await api.postGetPriceResults({
        authToken,
        deployment,
        ipAddress,
        isBot,
        soldSort,
        upcomingSort,
        ...rest,
    });

    return { ...payload, pageType };
};

export const getPriceResults = createAsyncThunk<ActionResult, ActionArgs>(
    'la/ui/priceResults/getPriceResults',
    fetchGetPriceResults
);
// We want two that do the same thing so we can have different reducers.
export const getNewPage = createAsyncThunk<ActionResult, ActionArgs>(
    'la/ui/priceResults/getNewPage',
    fetchGetPriceResults
);

export const getPriceResultsData = createAsyncThunk<GetPriceResultsDataPayload, string>(
    'la/ui/priceResults/getPriceResultsData',
    async (slug, { getState, rejectWithValue }) => {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);
        const showPage = getShowPage(state);

        if (!showPage) {
            return rejectWithValue('Useless call');
        }

        const { payload } = await api.postGetPriceResultsData({
            authToken,
            deployment,
            slug,
        });

        return payload;
    }
);
