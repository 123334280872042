import { AppDispatch, AppGetState, GlobalState } from '@/redux/store';
import { createSelector } from '@reduxjs/toolkit';
import { getAuthToken, getBidderId, isUserLoggedIn } from '@/redux/modules/account/user/user.selectors';
import { getDeployment } from './config';
import { LOG_OUT_BIDDER } from '@/redux/modules/account/logout/logout.actions';
import api from '@/redux/api/recommended';
import ms from 'ms';

import { REHYDRATE } from 'redux-persist/constants';

/* Action Types */
export const LOAD_RECOMMENDED_ITEMS_FAIL = 'la/domain/recommendedItem/LOAD_FAIL';
export const LOAD_RECOMMENDED_ITEMS_REQUEST = 'la/domain/recommendedItem/LOAD_REQUEST';
export const LOAD_RECOMMENDED_ITEMS_SUCCESS = 'la/domain/recommendedItem/LOAD_SUCCESS';

const REDUX_STORE_TIME = ms('30m');

/* reducer */
export type RecommendedItemSlice = {
    ids: any[];
    loaded: number;
    loading: boolean;
};

export const defaultRecommendedItemSlice: RecommendedItemSlice = {
    ids: [],
    loaded: 0,
    loading: false,
};

export default function reducer(
    state: RecommendedItemSlice = defaultRecommendedItemSlice,
    action: any = {}
): RecommendedItemSlice {
    let existing;
    let time;

    switch (action.type) {
        case REHYDRATE:
            if (Object.prototype.hasOwnProperty.call(action.payload, 'recommendedItem')) {
                const incoming = action.payload.recommendedItem;
                return { ...state, ...incoming };
            }

            return state;
        case LOAD_RECOMMENDED_ITEMS_FAIL:
            return {
                ...state,
                loading: false,
            };
        case LOAD_RECOMMENDED_ITEMS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case LOAD_RECOMMENDED_ITEMS_SUCCESS:
            existing = [...state.ids];
            time = action.meta.actionTime;

            if (action.payload) {
                action.payload.forEach((item) => {
                    existing.push(item.itemId);
                });
            }

            return {
                ...state,
                ids: [...new Set(existing)],
                loaded: time,
                loading: false,
            };
        case LOG_OUT_BIDDER:
            return {
                ...defaultRecommendedItemSlice,
            };

        default:
            return state;
    }
}

/* SELECTORS */
const stateSelector = (state: GlobalState) => state;
const recommendedItemSlice = createSelector(stateSelector, (state) => state.recommendedItem);

const idSelector = (_: GlobalState, id: number) => id;

const byIdSelector = createSelector(recommendedItemSlice, (state) => state.ids);

const getLoadTimeForRecommendedItem = createSelector(recommendedItemSlice, (state) => state.loaded);

const isRecommendedItemLoading = createSelector(recommendedItemSlice, (state) => state.loading);

export const getSortedRecommendedItemIdsFromState = createSelector([byIdSelector, idSelector], (ids, limit) => {
    if (limit) {
        return ids.slice(0, limit);
    }
    return ids || [];
});

const shouldFetchRecommendedItem = createSelector(
    [getLoadTimeForRecommendedItem, isRecommendedItemLoading],
    (loaded, loading) => {
        const time = Date.now();
        const diff = time - loaded;
        if (diff < REDUX_STORE_TIME) {
            return false;
        }
        return !loading;
    }
);

/* ACTION CREATORS */
const fetchRecommendedItem = (amazon) => async (dispatch, getState) => {
    try {
        const state = getState();
        const userLoggedIn = isUserLoggedIn(state);

        if (!userLoggedIn) {
            return Promise.resolve();
        }

        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);
        const bidderId = getBidderId(state);

        dispatch({
            type: LOAD_RECOMMENDED_ITEMS_REQUEST,
        });
        const response = await api.fetchRecommendedItem({
            amazon,
            authToken,
            bidderId,
            deployment,
        });
        dispatch({
            meta: { actionTime: Date.now() },
            payload: response.data,
            type: LOAD_RECOMMENDED_ITEMS_SUCCESS,
        });
    } catch (error) {
        dispatch({
            error: true,
            payload: error,
            type: LOAD_RECOMMENDED_ITEMS_FAIL,
        });
    }
};

export const fetchRecommendedItemIfNeeded =
    (amazon?: boolean) => async (dispatch: AppDispatch, getState: AppGetState) => {
        const needed = shouldFetchRecommendedItem(getState());
        if (needed || amazon) {
            return dispatch(fetchRecommendedItem(amazon));
        }
        return Promise.resolve();
    };
