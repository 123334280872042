import { AppDispatch, AppGetState, GlobalState } from '@/redux/store';
import { createSelector } from '@reduxjs/toolkit';
import { getAuthToken } from '@/redux/modules/account/user/user.selectors';
import { getBalanceDueSubtotal } from './checkout/checkout.selectors';
import { getDeployment } from './config';
import { getProcessingFees } from '@/redux/api/payment';
import { ProcessingFees } from '@/types/ProcessingFees';
import cloneDeep from 'lodash/cloneDeep';
import difference from 'lodash/difference';
import isEmpty from 'lodash/isEmpty';
import ms from 'ms';
import union from 'lodash/union';

/* Action Types */
export const LOAD_PROCESSING_FEE_REQUEST = 'la/ui/processingFee/LOAD_PROCESSING_FEE_REQUEST';
export const LOAD_PROCESSING_FEE_SUCCESS = 'la/ui/processingFee/LOAD_PROCESSING_FEE_SUCCESS';
export const LOAD_PROCESSING_FEE_FAIL = 'la/ui/processingFee/LOAD_PROCESSING_FEE_FAIL';

const REDUX_STORE_TIME = ms('30m');

export type ProcessingFeeSlice = {
    byCatalogId: { [catalogId: number]: any };
    loaded: { [catalogId: number]: number };
    loading: number[];
    uiState: {
        error: string;
        loading: boolean;
        success: boolean;
    };
};

export const defaultProcessingFeeSlice: ProcessingFeeSlice = {
    byCatalogId: {},
    loaded: {},
    loading: [],
    uiState: {
        error: '',
        loading: false,
        success: false,
    },
};

export default function reducer(
    state: ProcessingFeeSlice = defaultProcessingFeeSlice,
    action: any = {}
): ProcessingFeeSlice {
    let existing;
    let loaded;
    let loading;
    let time;

    switch (action.type) {
        case LOAD_PROCESSING_FEE_FAIL:
            return {
                ...state,
                loading: difference(state.loading, [action.meta.catalogId]),
                uiState: {
                    ...state.uiState,
                    error: 'Failed to load processing fees',
                    loading: false,
                    success: false,
                },
            };
        case LOAD_PROCESSING_FEE_REQUEST:
            return {
                ...state,
                loading: union(state.loading, [action.payload]),
                uiState: {
                    ...state.uiState,
                    error: '',
                    loading: true,
                    success: false,
                },
            };
        case LOAD_PROCESSING_FEE_SUCCESS:
            time = action.meta.actionTime;
            existing = cloneDeep(state.byCatalogId);
            loaded = { ...state.loaded, [action.meta.catalogId]: time };
            loading = cloneDeep(state.loading);
            existing[action.meta.catalogId] = action.payload;
            loading = difference(loading, [action.meta.catalogId]);
            return {
                ...state,
                byCatalogId: existing,
                loaded,
                loading,
                uiState: {
                    ...state.uiState,
                    error: '',
                    loading: false,
                    success: true,
                },
            };
        default:
            return state;
    }
}

/* SELECTORS */
const stateSelector = (state: GlobalState) => state;
const processingFeeSlice = createSelector(stateSelector, (state) => state.processingFee);

const idSelector = (_: GlobalState, id: number) => id;

const byIdSelector = createSelector(processingFeeSlice, (state) => state.byCatalogId);

const loadedSelector = createSelector(processingFeeSlice, (state) => state.loaded);

const loadingSelector = createSelector(processingFeeSlice, (state) => state.loading);

export const getFees = createSelector(
    [byIdSelector, idSelector],
    (byId, id): ProcessingFees =>
        byId[id] || {
            achFee: 0,
            balanceWithACHFee: 0,
            balanceWithCCFee: 0,
            balanceWithHRFee: 0,
            ccFee: 0,
            hrFee: 0,
        }
);
export const getFeeError = createSelector(processingFeeSlice, (localState) => localState.uiState.error);
export const getFeeLoadSuccess = createSelector(processingFeeSlice, (localState) => localState.uiState.success);
export const getFeeLoading = createSelector(processingFeeSlice, (localState) => localState.uiState.loading);

const getLoadTimeForFees = createSelector([loadedSelector, idSelector], (loaded, id) => loaded[id] || 0);
const isFeesLoading = createSelector([loadingSelector, idSelector], (loading, id) => loading.includes(id));

const shouldFetchFees = createSelector(
    [idSelector, getFees, getLoadTimeForFees, isFeesLoading],
    (catalogId, invoice, loaded, loading) => {
        if (!catalogId) {
            return false;
        }
        if (!isEmpty(invoice)) {
            const time = Date.now();
            const diff = time - loaded;
            if (diff < REDUX_STORE_TIME) {
                return false;
            }
        }
        return !loading;
    }
);

/* ACTION CREATORS */
export const fetchProcessingFeesIfNeeded =
    (catalogId: number, force: boolean = false) =>
    async (dispatch: AppDispatch, getState: AppGetState) => {
        const state = getState();
        if (shouldFetchFees(state, catalogId) || force) {
            try {
                const authToken = getAuthToken(state);
                const deployment = getDeployment(state);
                const balanceDueSubtotal = getBalanceDueSubtotal(state, catalogId);

                dispatch({
                    meta: { actionTime: Date.now() },
                    payload: catalogId,
                    type: LOAD_PROCESSING_FEE_REQUEST,
                });
                // @ts-ignore
                const balanceDueCents = Math.round(parseFloat(Number(balanceDueSubtotal)) * 100);
                const response = await getProcessingFees({
                    authToken,
                    balanceDue: balanceDueCents,
                    catalogId,
                    deployment,
                });
                dispatch({
                    meta: { actionTime: Date.now(), catalogId },
                    payload: response.payload,
                    type: LOAD_PROCESSING_FEE_SUCCESS,
                });
            } catch (error) {
                dispatch({
                    error: true,
                    meta: { catalogId },
                    payload: error,
                    type: LOAD_PROCESSING_FEE_FAIL,
                });
            }
        } else {
            return Promise.resolve();
        }
    };
