import { AppDispatch, AppGetState, GlobalState } from '@/redux/store';
import { createSelector } from '@reduxjs/toolkit';
import { getAuthToken } from '@/redux/modules/account/user/user.selectors';
import { getDeployment } from './config';
import api from '@/redux/api/recent';
import cloneDeep from 'lodash/cloneDeep';
import ms from 'ms';

import { REHYDRATE } from 'redux-persist/constants';
import { TRACK_PAGEVIEW } from './actions';

/* Action Types */
const LOAD_RECENT_ITEMS_FAIL = 'la/domain/recentItems/LOAD_FAIL';
const LOAD_RECENT_ITEMS_REQUEST = 'la/domain/recentItems/LOAD_REQUEST';
const LOAD_RECENT_ITEMS_SUCCESS = 'la/domain/recentItems/LOAD_SUCCESS';

const REDUX_STORE_TIME = ms('30m');

/* reducer */
export type RecentItemSlice = {
    byId: {};
    loaded: number;
    loading: boolean;
};

export const defaultRecentItemSlice: RecentItemSlice = {
    byId: {},
    loaded: 0,
    loading: false,
};

export default function reducer(state: RecentItemSlice = defaultRecentItemSlice, action: any = {}): RecentItemSlice {
    let existing;
    let time;
    let id;
    let pageType;

    switch (action.type) {
        case REHYDRATE:
            if (Object.prototype.hasOwnProperty.call(action.payload, 'recentItem')) {
                const incoming = action.payload.recentItem;
                return { ...state, ...incoming };
            }

            return state;
        case LOAD_RECENT_ITEMS_FAIL:
            return {
                ...state,
                loading: false,
            };
        case LOAD_RECENT_ITEMS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case LOAD_RECENT_ITEMS_SUCCESS:
            existing = cloneDeep(state.byId);
            time = action.meta.actionTime;

            if (action.payload.recentItems) {
                action.payload.recentItems.forEach((x) => {
                    existing[x.itemId] = { ...x };
                });
            }

            return {
                ...state,
                byId: existing,
                loaded: time,
                loading: false,
            };
        case TRACK_PAGEVIEW:
            existing = cloneDeep(state.byId);
            id = action.meta.analytics.eventPayload.properties.itemId;
            pageType = action.meta.analytics.eventPayload.properties.pageType;

            if (pageType === 'item' || pageType === 'sold item') {
                existing[id] = {
                    itemId: id,
                    timestamp: action.meta.actionTime,
                };
                return {
                    ...state,
                    byId: existing,
                };
            } else {
                return state;
            }

        default:
            return state;
    }
}

/* SELECTORS */
const stateSelector = (state: GlobalState) => state;
const recentItemSlice = createSelector(stateSelector, (state) => state.recentItem);

const idSelector = (_: GlobalState, id: number) => id;

const byIdSelector = createSelector(recentItemSlice, (state) => state.byId);

const getLoadTimeForRecentItems = createSelector(recentItemSlice, (state) => state.loaded);

const isRecentItemsLoading = createSelector(recentItemSlice, (state) => state.loading);

const getSortedRecentItemIdsFromState = createSelector(byIdSelector, (byId) => {
    const toBeSorted = Object.keys(byId).map((item) => byId[item]);
    const sorted = toBeSorted.sort((a, b) => b.timestamp - a.timestamp);
    return sorted.map((item): number => item.itemId);
});

export const getLimitedSortedRecentItemIdsFromState = createSelector(
    [getSortedRecentItemIdsFromState, idSelector],
    (sortedRecentItemIds, limit) => {
        // prevent 0's from being returned which happens sometimes
        return sortedRecentItemIds.filter((id) => id).slice(0, limit);
    }
);

export const getViewTime = createSelector([byIdSelector, idSelector], (byId, id) => {
    const x = byId[id];
    return x && x.timestamp ? x.timestamp : 0;
});

const shouldFetchRecentItems = createSelector([getLoadTimeForRecentItems, isRecentItemsLoading], (loaded, loading) => {
    const time = Date.now();
    const diff = time - loaded;
    if (diff < REDUX_STORE_TIME) {
        return false;
    }
    return !loading;
});

/* ACTION CREATORS */
const fetchRecentItems = () => async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);
        dispatch({
            type: LOAD_RECENT_ITEMS_REQUEST,
        });
        const response = await api.fetchRecentItems({
            authToken,
            deployment,
        });
        dispatch({
            meta: { actionTime: Date.now() },
            payload: response.data,
            type: LOAD_RECENT_ITEMS_SUCCESS,
        });
    } catch (error) {
        dispatch({
            error: true,
            payload: error,
            type: LOAD_RECENT_ITEMS_FAIL,
        });
    }
};

export const fetchRecentItemsIfNeeded = () => async (dispatch: AppDispatch, getState: AppGetState) => {
    const needed = shouldFetchRecentItems(getState());
    if (needed) {
        return dispatch(fetchRecentItems());
    }
    return Promise.resolve();
};
