export enum VerifyMobileNumberStatus {
    error = 'error',
    expired = 'expired',
    pending = 'pending',
    verified = 'verified',
    verifyingCode = 'verifyingCode',
}

export type PhoneVerifySlice = {
    errorMessage: string;
    phoneNumber: string;
    referenceNumber: string;
    status: VerifyMobileNumberStatus;
};

export const defaultPhoneVerifySlice: PhoneVerifySlice = {
    errorMessage: '',
    phoneNumber: '',
    referenceNumber: '',
    status: VerifyMobileNumberStatus.pending,
};

/**
 * @category User API
 * @see postVerifyMobileNumber
 */
export type PostVerifyMobileNumberResponse = {
    error: boolean;
    payload: VerifyMobileNumberPayload;
};

/**
 * @category User API
 * @see postVerifyMobileNumber
 */
export type VerifyMobileNumberPayload = {
    bidderId: number;
    channel: string;
    clientIPAddress: string;
    initiatedTime: string;
    lastUpdatedTime: string;
    mobileVerificationId: number;
    phoneNumber: string;
    provider: string;
    referenceNumber: string;
    responseTime: string;
    status: 'pending' | 'approved' | 'canceled';
    valid: boolean;
};
