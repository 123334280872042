import { ApiHosts, handleResponseOld, makeGet } from '@/redux/api/helpers';
import {
    GetPriceResultsDataParams,
    GetPriceResultsDataResponse,
    GetPriceResultsParams,
    GetPriceResultsResponse,
} from './priceResults.types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const postGetPriceResults = ({ authToken, deployment, ipAddress, ...rest }: GetPriceResultsParams) =>
    new Promise<GetPriceResultsResponse>((resolve, reject) => {
        const request = makeGet({
            apiPath: 'priceresultitems',
            authToken,
            deployment,
            ipAddress,
            path: ApiHosts.Search,
        });
        const query = { ...rest };
        request.query(query);
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });

export const postGetPriceResultsData = ({ authToken, deployment, ...rest }: GetPriceResultsDataParams) =>
    new Promise<GetPriceResultsDataResponse>((resolve, reject) => {
        const request = makeGet({
            apiPath: 'priceresultsummary',
            authToken,
            deployment,
            path: ApiHosts.CatalogManagement,
        });
        const query = { ...rest };
        request.query(query);
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });
