import { createAsyncThunk } from '@/redux/createAsyncThunk';
import { getAuthToken, getBidderId } from '@/redux/modules/account/user/user.selectors';
import { getDeployment } from '@/redux/modules/config';
import { postPhoneVerificationCode, postVerifyMobileNumber } from './phoneVerify.api';
import { VerifyMobileNumberPayload } from './phoneVerify.types';

export const verifyMobileNumber = createAsyncThunk<VerifyMobileNumberPayload, string>(
    'la/domain/user/phoneVerification',
    async (phoneNumber, { getState }) => {
        const state = getState();
        const authToken = getAuthToken(state);
        const bidderId = getBidderId(state);
        const deployment = getDeployment(state);

        const { payload } = await postVerifyMobileNumber({
            authToken,
            bidderId,
            deployment,
            phoneNumber,
        });

        return payload;
    }
);

export const verifyMobileNumberOnFile = createAsyncThunk<VerifyMobileNumberPayload, void>(
    'la/domain/user/phoneVerification',
    async (voidArg, { getState }) => {
        const state = getState();
        const authToken = getAuthToken(state);
        const bidderId = getBidderId(state);
        const deployment = getDeployment(state);

        const { payload } = await postVerifyMobileNumber({
            authToken,
            bidderId,
            deployment,
        });

        return payload;
    }
);

export type SendPhoneVerificationCodeParams = {
    phoneNumber: string;
    referenceNumber: string;
    verificationCode: string;
};

export const sendPhoneVerificationCode = createAsyncThunk<string, SendPhoneVerificationCodeParams>(
    'la/domain/user/sendPhoneVerificationCode',
    async ({ phoneNumber, referenceNumber, verificationCode }, { getState }) => {
        const state = getState();
        const authToken = getAuthToken(state);
        const bidderId = getBidderId(state);
        const deployment = getDeployment(state);
        const response = await postPhoneVerificationCode({
            authToken,
            bidderId,
            deployment,
            phoneNumber,
            referenceNumber,
            verificationCode,
        });

        return response.payload.status;
    }
);
