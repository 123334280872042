import { createSlice } from '@reduxjs/toolkit';
import { defaultPhoneVerifySlice, VerifyMobileNumberStatus } from './phoneVerify.types';
import { sendPhoneVerificationCode, verifyMobileNumber } from './phoneVerify.actions';

const phoneVerifySlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(verifyMobileNumber.pending, (slice, { meta }) => {
            slice.phoneNumber = meta.arg;
            slice.status = VerifyMobileNumberStatus.pending;
            slice.errorMessage = '';
        });

        builder.addCase(verifyMobileNumber.fulfilled, (slice, response) => {
            slice.phoneNumber = response.meta.arg;
            slice.referenceNumber = response.payload.referenceNumber;
            slice.errorMessage = '';
        });

        builder.addCase(verifyMobileNumber.rejected, (slice, response) => {
            slice.phoneNumber = response.meta.arg;
            slice.errorMessage = response.error.message;
            slice.status = VerifyMobileNumberStatus.pending;
        });

        builder.addCase(sendPhoneVerificationCode.pending, (slice) => {
            slice.status = VerifyMobileNumberStatus.verifyingCode;
            slice.errorMessage = '';
        });

        builder.addCase(sendPhoneVerificationCode.fulfilled, (slice, { payload }) => {
            if (payload === 'approved') {
                slice.status = VerifyMobileNumberStatus.verified;
            } else if (payload === 'canceled') {
                slice.status = VerifyMobileNumberStatus.expired;
            } else if (payload === 'pending') {
                slice.status = VerifyMobileNumberStatus.pending;
            }
            slice.errorMessage = '';
        });

        builder.addCase(sendPhoneVerificationCode.rejected, (slice) => {
            slice.status = VerifyMobileNumberStatus.error;
            slice.errorMessage = 'Server error';
        });
    },
    initialState: defaultPhoneVerifySlice,
    name: 'phoneVerify',
    reducers: {},
});

export const { reducer: phoneVerifyReducer } = phoneVerifySlice;
