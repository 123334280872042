import { ApiHosts, handleResponseOld, makePost } from '@/redux/api/helpers';
import { PostVerifyMobileNumberResponse, VerifyMobileNumberPayload } from './phoneVerify.types';

/**
 * @category User API
 * @see postVerifyMobileNumber
 */
type PostVerifyMobileNumber = {
    authToken: string;
    bidderId: number;
    deployment: string;
    phoneNumber?: string;
};

/**
 * Call for mobile phone verification by Bidder ID via POST to User API service
 * @function postVerifyMobileNumber
 * @category User API
 * @param {PostVerifyMobileNumber}
 * @see https://api-DEPLOYMENT.liveauctioneers.com/user/BIDDER_ID/mobile-number/verify?channel=sms&phoneNumber=PHONE_NUMBER
 * @see https://github.com/LIVEauctioneers/TBD
 */
export const postVerifyMobileNumber = ({ authToken, bidderId, deployment, phoneNumber }: PostVerifyMobileNumber) =>
    new Promise<PostVerifyMobileNumberResponse>((resolve, reject) => {
        const request = makePost({
            apiPath: `${bidderId}/mobile-number/verify`,
            authToken,
            deployment,
            forceCredentials: true,
            path: ApiHosts.FlynnUser,
        });

        request.send({
            channel: 'sms',
            // if number is not provided, verify service uses sms number on file
            ...(phoneNumber && { phoneNumber }),
        });

        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });

/**
 * @category User API
 * @see postPhoneVerificationCode
 */
type PostPhoneVerificationCodeResponse = {
    error: boolean;
    payload: VerifyMobileNumberPayload;
};

/**
 * @category User API
 * @see postPhoneVerificationCode
 */
type PostPhoneVerificationCodeParams = {
    authToken: string;
    bidderId: number;
    deployment: string;
    phoneNumber: string;
    referenceNumber: string;
    verificationCode: string;
};

/**
 * Sends code supplied by user to Phone Verification service
 * @function postPhoneVerificationCode
 * @category User API
 * @param {PostPhoneVerificationCodeParams}
 * @see https://api-DEPLOYMENT.liveauctioneers.com/user/BIDDER_ID/mobile-number/verify
 */
export const postPhoneVerificationCode = ({
    authToken,
    bidderId,
    deployment,
    referenceNumber,
    verificationCode,
}: PostPhoneVerificationCodeParams) =>
    new Promise<PostPhoneVerificationCodeResponse>((resolve, reject) => {
        const request = makePost({
            apiPath: `${bidderId}/mobile-number/verify-check`,
            authToken,
            deployment,
            forceCredentials: true,
            path: ApiHosts.FlynnUser,
        });
        request.send({
            code: verificationCode,
            mobileVerificationId: 4,
            referenceNumber,
        });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });
