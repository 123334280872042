import { ActionWithPayload } from '@/types/redux';
import { AppDispatch, AppGetState, GlobalState } from '@/redux/store';
import { createSelector, Reducer } from '@reduxjs/toolkit';
import { getAuthToken, getUserData } from '@/redux/modules/account/user/user.selectors';
import { getDeployment } from './config';
import { getLoginAnalytics } from './analytics';
import { handleActions } from 'redux-actions';
import { LOAD_BIDDER_DATA_SUCCESS } from '@/redux/modules/account/user/user.types';
import { LOG_OUT_BIDDER } from '@/redux/modules/account/logout/logout.actions';
import { LOGIN_SUCCESS, LoginSuccessAction } from '@/redux/modules/account/login/login.types';
import { SEND_RESELLER_TYPE_FAIL, SEND_RESELLER_TYPE_REQUEST, SEND_RESELLER_TYPE_SUCCESS } from './actions';
import api from '@/redux/api/reseller';
import ResellerTypes from '@/enums/resellerTypes';

// reducer
export type ResellerState = {
    error: boolean;
    loaded: number;
    loading: boolean;
    resellerSelection: ResellerTypes;
};

export const DEFAULT_STATE: ResellerState = {
    error: false,
    loaded: 0,
    loading: false,
    resellerSelection: ResellerTypes.NOT_A_RESELLER,
};

export const reducer: Reducer<ResellerState> = handleActions(
    {
        [LOAD_BIDDER_DATA_SUCCESS]: (state: ResellerState, action: any) => ({
            ...state,
            loaded: action.meta.actionTime,
            loading: false,
            resellerSelection: action.payload.resellerType,
        }),
        [LOG_OUT_BIDDER]: () => ({
            ...DEFAULT_STATE,
        }),
        [LOGIN_SUCCESS]: (state: ResellerState, action: LoginSuccessAction) => ({
            ...state,
            loaded: action.meta.actionTime,
            loading: false,
            resellerSelection: action.payload.resellerType,
        }),
        [SEND_RESELLER_TYPE_FAIL]: (state: ResellerState, action: ActionWithPayload<{ error: string }>) => ({
            ...state,
            error: action.error,
            loading: false,
        }),
        [SEND_RESELLER_TYPE_REQUEST]: (state: ResellerState) => ({
            ...state,
            loading: true,
        }),
        [SEND_RESELLER_TYPE_SUCCESS]: (state: ResellerState, action: any) => ({
            ...state,
            loading: false,
            resellerSelection: action.payload.resellerType,
        }),
    },
    DEFAULT_STATE
);

/* SELECTORS */
const stateSelector = (state: GlobalState) => state;
const resellerSlice = createSelector(stateSelector, (state) => state.reseller);

export const getResellerType = createSelector(resellerSlice, (state) => state.resellerSelection);

export const getIsReseller = createSelector(
    resellerSlice,
    (state) => state.resellerSelection === ResellerTypes.RESELLER
);

/* ACTION CREATORS */

export const sendResellerType = (resellerSelection: number) => async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
        dispatch({
            type: SEND_RESELLER_TYPE_REQUEST,
        });
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);
        const userData = getUserData(state);
        const resellerType = resellerSelection ? ResellerTypes.RESELLER : ResellerTypes.NOT_A_RESELLER;

        const response = await api.postResellerType({
            authToken,
            bidderId: userData.bidderId,
            deployment,
            resellerType,
        });

        const analytics = getLoginAnalytics(userData, resellerType);
        return dispatch({
            meta: { analytics: analytics.analytics },
            payload: { message: response.payload, resellerType },
            type: SEND_RESELLER_TYPE_SUCCESS,
        });
    } catch (error) {
        return dispatch({
            error: true,
            payload: error.message || error,
            type: SEND_RESELLER_TYPE_FAIL,
        });
    }
};
