import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { defaultPriceResultsSlice, PageType, SoldSortKeys, UpcomingSortKeys } from './priceResults.types';
import { getNewPage, getPriceResults, getPriceResultsData } from './priceResults.actions';

const priceResultsSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(getPriceResults.fulfilled, (slice, { payload }) => {
            // We don't want the data twice in our store. Already is in item card model
            slice.upcomingSalesItemIds = payload.upcomingSalesItems.map((item) => item.itemId);
            slice.salesHistoryItemIds = payload.salesHistoryItems.map((item) => item.itemId);

            // Append static info.
            slice.viewMoreUrl = payload.viewMoreUrl;
            slice.savedSearchKey = payload.savedSearchKey;
        });

        builder.addCase(getNewPage.fulfilled, (slice, { payload }) => {
            // We don't want the data twice in our store. Already is in item card model
            const upcomingSalesItemIds = payload.upcomingSalesItems.map((item) => item.itemId);
            const salesHistoryItemIds = payload.salesHistoryItems.map((item) => item.itemId);

            // Append if applicable
            if (payload.pageType === PageType.Upcoming) {
                slice.upcomingSalesItemIds = [...slice.upcomingSalesItemIds, ...upcomingSalesItemIds];
                slice.salesHistoryItemIds = salesHistoryItemIds;
            } else if (payload.pageType === PageType.Sold) {
                slice.upcomingSalesItemIds = upcomingSalesItemIds;
                slice.salesHistoryItemIds = [...slice.salesHistoryItemIds, ...salesHistoryItemIds];
            } else {
                slice.salesHistoryItemIds = salesHistoryItemIds;
                slice.upcomingSalesItemIds = upcomingSalesItemIds;
            }

            // Append static info.
            slice.viewMoreUrl = payload.viewMoreUrl;
            slice.savedSearchKey = payload.savedSearchKey;
        });

        builder.addCase(getPriceResultsData.fulfilled, (slice, { payload }) => {
            slice.medianPrice = payload.hammerPriceMedian;
            slice.priceRangeHigh = payload.hammerPriceMax;
            slice.priceRangeLow = payload.hammerPriceMin;
            slice.slug = payload.slug;
            slice.slugItemId = payload.lotId;
            slice.totalSales = payload.numberOfSoldLots;
        });
    },
    initialState: defaultPriceResultsSlice,
    name: 'priceResults',
    reducers: {
        goToPriceResultItemView: (state) => {
            state.showPage = false;
        },
        goToPriceResultSummaryView: (state) => {
            state.showPage = true;
        },
        updateSoldSort: (state, { payload }: PayloadAction<SoldSortKeys>) => {
            state.soldSort = payload;
        },
        updateUpcomingSort: (state, { payload }: PayloadAction<UpcomingSortKeys>) => {
            state.upcomingSort = payload;
        },
    },
});

export const { reducer: priceResultsReducer } = priceResultsSlice;

export const { goToPriceResultItemView, goToPriceResultSummaryView, updateSoldSort, updateUpcomingSort } =
    priceResultsSlice.actions;
