import { ItemSummary } from '@/types/item/ItemSummary';
import { SearchSortAndDirection } from '@/types/search/enums/sortTypes';
import { WithAuthAndDeployment } from '@/redux/commonTypes';

export enum PageType {
    Sold,
    Upcoming,
}

// Relevance is default sort on backend
export enum SoldSortKeys {
    BestMatch = SearchSortAndDirection.BestMatch,
    TimeMostRecent = SearchSortAndDirection.TimeMostRecent,
    HammerPriceHighest = SearchSortAndDirection.HammerPriceHighest,
}

export enum UpcomingSortKeys {
    BestMatch = SearchSortAndDirection.BestMatch,
    NewlyListed = SearchSortAndDirection.NewlyListed,
    MostBids = SearchSortAndDirection.MostBids,
    EstimateHighest = SearchSortAndDirection.EstimateHighest,
    PriceHighest = SearchSortAndDirection.PriceHighest,
    PriceLowest = SearchSortAndDirection.PriceLowest,
}

export type PriceResultsSlice = {
    medianPrice: number;
    priceRangeHigh: number;
    priceRangeLow: number;
    salesHistoryItemIds: number[];
    savedSearchKey: string;
    showPage: boolean;
    slug: string;
    slugItemId: number;
    soldSort: SoldSortKeys;
    totalSales: number;
    upcomingSalesItemIds: number[];
    upcomingSort: UpcomingSortKeys;
    viewMoreUrl: string;
};

export const defaultPriceResultsSlice: PriceResultsSlice = {
    medianPrice: 0,
    priceRangeHigh: 0,
    priceRangeLow: 0,
    salesHistoryItemIds: [],
    savedSearchKey: '',
    showPage: true,
    slug: '',
    slugItemId: 0,
    soldSort: SoldSortKeys.BestMatch,
    totalSales: 0,
    upcomingSalesItemIds: [],
    upcomingSort: UpcomingSortKeys.BestMatch,
    viewMoreUrl: '',
};

export type GetPriceResultsParams = WithAuthAndDeployment<{
    ipAddress?: string;
    isBot?: boolean;
    itemId?: number;
    page?: number;
    slug: string;
    soldSort?: SoldSortKeys;
    upcomingSort?: UpcomingSortKeys;
}>;

export type GetPriceResultsPayload = {
    salesHistoryItems: ItemSummary[];
    savedSearchKey: string;
    upcomingSalesItems: ItemSummary[];
    viewMoreUrl: string;
};

export type GetPriceResultsResponse = {
    error: boolean;
    payload: GetPriceResultsPayload;
};

export type GetPriceResultsDataParams = WithAuthAndDeployment<{
    slug: string;
}>;

export type GetPriceResultsDataPayload = {
    hammerPriceMax: number;
    hammerPriceMedian: number;
    hammerPriceMin: number;
    lotId: number;
    numberOfSoldLots: number;
    slug: string;
};

export type GetPriceResultsDataResponse = {
    error: boolean;
    payload: GetPriceResultsDataPayload;
};
